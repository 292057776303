<template>
  <div class="invoice-preview-wrapper">
    <b-row>
      <b-col md="9">
        <b-card
          v-if="isLoading"
          class="text-center"
        >
          <b-spinner variant="primary" />
        </b-card>
        <vue-pdf
          v-else
          ref="invoice-pdf"
          :float-layout="false"
          :enable-download="false"
          :manual-pagination="true"
          :pdf-quality="2"
          pdf-format="a4"
          pdf-content-width="100%"
          :html-to-pdf-options="{
            filename: `${invoiceData.sale_id || 'sale_invoice'}`,
            html2canvas: {
              useCORS: true
            },
            image: {
              type: 'jpeg',
              quality: 1
            },
            jsPDF: {
              unit: 'in',
              format: 'a4',
            }
          }"
          @beforeDownload="preparePdf($event)"
        >
          <div
            slot="pdf-content"
            class="invoice-preview"
          >
            <b-card
              no-body
              class="invoice-preview-card mb-0 mb-1 shadow-none"
              :class="invoiceData.payments && invoiceData.payments.length ? 'html2pdf__page-break' : null"
            >
              <div class="d-flex flex-column">
                <!-- Header -->
                <b-card-body class="invoice-padding pb-0">
                  <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing invoice-header my-0">
                    <!-- Header: Left Content -->
                    <div class="invoice-left-content">
                      <div class="mb-1">
                        <img
                          src="https://ik.imagekit.io/w13m3d3rhif/web/icons/go-zayaan-logo-brand.svg"
                          alt="gozayaan-logo"
                        >
                      </div>
                      <div class="invoice-info">
                        <p>
                          Sheltech Ayaan, House 58, Road 6 & 11
                        </p>
                        <p>
                          Block C, Level 2, Banani, Dhaka.
                        </p>
                        <p>
                          Hotline: +88 09678 332211
                        </p>
                      </div>
                    </div>
                    <!-- Header: Right Content -->
                    <div class="invoice-right-content">
                      <div class="mt-1">
                        <h4 class="invoice-title">
                          Invoice
                          <span class="invoice-number">{{ invoiceData.sale_id }}</span>
                        </h4>
                      </div>
                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title">
                          Date Issued:
                        </p>
                        <p class="invoice-date ml-0">
                          {{ formatDate(invoiceData.created_at) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </b-card-body>
                <hr class="invoice-spacing">
                <b-card-body class="invoice-padding py-0">
                  <div class="invoice-info my-2">
                    <h5 class="font-weight-bolder mb-0">
                      Invoice To:
                    </h5>
                    <p class="font-weight-bold mb-0">
                      {{ `${invoiceData.customer_first_name} ${invoiceData.customer_last_name}` }}
                    </p>
                    <p class="mb-0">
                      {{ invoiceData.customer_phone || 'N/A' }}
                    </p>
                    <p>
                      {{ invoiceData.customer_email || 'N/A' }}
                    </p>
                  </div>
                </b-card-body>
                <b-table-simple
                  bordered="bordered"
                  outlined="outlined"
                  responsive
                  class="mt-2 mb-5 invoice-table invoice-padding"
                >
                  <b-thead>
                    <b-tr>
                      <b-th>Product</b-th>
                      <b-th>Description</b-th>
                      <b-th>Reference</b-th>
                      <b-th>Price</b-th>
                      <b-th>Discount</b-th>
                      <b-th>Total</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr
                      v-for="(saleItem, index) in invoiceData.items"
                      :key="index"
                    >
                      <b-td>{{ saleItem.product_type.replace('_', ' ') }}</b-td>
                      <b-td class="description">
                        {{ saleItem.description || 'N/A' }}
                      </b-td>
                      <b-td>{{ saleItem.reference || 'N/A' }}</b-td>
                      <b-td>BDT {{ Number(saleItem.price).toLocaleString() }}</b-td>
                      <b-td>BDT {{ Number(saleItem.discount).toLocaleString() }}</b-td>
                      <b-td>BDT {{ (Number(saleItem.price) - Number(saleItem.discount)).toLocaleString() }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
                <b-card-body class="invoice-padding pb-0">
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                      class="mt-md-0 mt-3"
                      order="2"
                      order-md="1"
                    />
                    <b-col
                      cols="12"
                      md="6"
                      class="mt-md-6 d-flex justify-content-end"
                      order="1"
                      order-md="2"
                    >
                      <div class="invoice-total-wrapper mb-1">
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">
                            Subtotal:
                          </p>
                          <p class="invoice-total-amount">
                            BDT {{ Number(invoiceData.total_price).toLocaleString() }}
                          </p>
                        </div>
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">
                            Discount:
                          </p>
                          <p class="invoice-total-amount">
                            BDT {{ Number(invoiceData.total_discount).toLocaleString() }}
                          </p>
                        </div>
                        <hr class="my-50">
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">
                            Total:
                          </p>
                          <p class="invoice-total-amount">
                            BDT {{ Number(invoiceData.total_payable).toLocaleString() }}
                          </p>
                        </div>
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">
                            Paid:
                          </p>
                          <p class="invoice-total-amount">
                            BDT {{ Number(invoiceData.total_paid).toLocaleString() }}
                          </p>
                        </div>
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">
                            Due:
                          </p>
                          <p class="invoice-total-amount">
                            BDT {{ Number(invoiceData.total_due).toLocaleString() }}
                          </p>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-body>
              </div>
            </b-card>
            <b-card
              v-if="invoiceData.payments && invoiceData.payments.length"
              no-body
              class="invoice-preview-card shadow-none"
            >
              <b-card-body class="py-0 invoice-padding">
                <div class="d-flex justify-content-between flex-md-row flex-column invoice-header my-0">
                  <!-- Header: Left Content -->
                  <div class="invoice-left-content">
                    <div class="my-1">
                      <img
                        src="https://ik.imagekit.io/w13m3d3rhif/web/icons/go-zayaan-logo-brand.svg"
                        alt="gozayaan-logo"
                      >
                    </div>
                    <div class="invoice-info">
                      <p>
                        Sheltech Ayaan, House 58, Road 6 & 11
                      </p>
                      <p>
                        Block C, Level 2, Banani, Dhaka.
                      </p>
                      <p>
                        Hotline: +88 09678 332211
                      </p>
                    </div>
                  </div>
                  <!-- Header: Right Content -->
                  <div class="invoice-right-content">
                    <div class="mt-1">
                      <h4 class="invoice-title">
                        Invoice
                        <span class="invoice-number">{{ invoiceData.sale_id }}</span>
                      </h4>
                    </div>
                    <div class="invoice-date-wrapper">
                      <p class="invoice-date-title">
                        Date Issued:
                      </p>
                      <p class="invoice-date ml-0">
                        {{ formatDate(invoiceData.created_at) }}
                      </p>
                    </div>
                  </div>
                </div>
              </b-card-body>
              <hr class="invoice-spacing">
              <b-card-body class="invoice-padding">
                <div class="invoice-info mt-2">
                  <h5 class="font-weight-bolder mb-1">
                    Transactions:
                  </h5>
                  <div
                    v-for="transaction in invoiceData.payments"
                    :key="transaction.id"
                    class="mb-2"
                  >
                    <p>
                      Date & Time:
                      <span>{{ formatDate(transaction.created_at, 'DD MMM YYYY, hh:mm A') }}</span>
                    </p>
                    <p>
                      Payment method:
                      <span>
                        {{ transaction.method === 'MFS' ? 'Mobile Banking' : transaction.method }}
                      </span>
                    </p>
                    <p>
                      Amount:
                      <span>
                        BDT {{ Number(transaction.amount).toLocaleString() }}
                      </span>
                    </p>
                    <template v-if="transaction.method === 'CARD'">
                      <p
                        v-if="transaction.card_number"
                      >
                        Card Number:
                        <span>{{ transaction.card_number }}</span>
                      </p>
                      <p
                        v-if="transaction.card_holder_name"
                      >
                        Card Holder's Name:
                        <span>{{ transaction.card_holder_name }} </span>
                      </p>
                    </template>
                    <template v-if="transaction.method === 'MFS'">
                      <p
                        v-if="transaction.gateway"
                      >
                        Gateway:
                        <span>{{ transaction.gateway }}</span>
                      </p>
                      <p
                        v-if="transaction.wallet_number"
                      >
                        Wallet Number:
                        <span>{{ transaction.wallet_number }}</span>
                      </p>
                    </template>
                    <template v-if="transaction.method === 'BANK'">
                      <p>
                        Customer Bank:
                        <span>{{ transaction.customer_bank }}</span>
                      </p>
                      <p>
                        Beneficiary Bank:
                        <span>{{ transaction.beneficiary_bank }}</span>
                      </p>
                    </template>
                    <p v-if="transaction.method !== 'CASH'">
                      Transaction ID:
                      <span>{{ transaction.trx_id }}</span>
                    </p>
                    <template v-if="transaction.method === 'CARD' || transaction.method === 'EMI'">
                      <p
                        v-if="transaction.approval_code"
                      >
                        Approval Code:
                        <span>{{ transaction.approval_code }} </span>
                      </p>
                    </template>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </vue-pdf>
      </b-col>
      <b-col md="3">
        <b-card>
          <b-button
            v-b-toggle.payment-info-sidebar
            variant="primary"
            block
          >
            Add Payment
          </b-button>
          <b-button
            variant="outline-primary"
            block
            @click="previewInvoice"
          >
            Print
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <payment-info
      :invoice="invoiceData"
      @getUpdatedInvoice="getInvoiceData"
    />
  </div>
</template>

<script>
import VuePdf from 'vue-html2pdf'
import helper from '@/mixins/helper'
import PaymentInfo from '@/components/sales-store/PaymentInfo.vue'
import api from '@/libs/api'

export default {
  name: 'InvoicePreview',
  components: {
    PaymentInfo,
    VuePdf,
  },
  mixins: [helper],
  data() {
    return {
      invoiceData: {},
      isLoading: false,
    }
  },
  mounted() {
    this.getInvoiceData()
  },
  methods: {
    getInvoiceData() {
      this.isLoading = true
      api.getData(`b2e_dashboard/stores/invoices/${this.$route.params.id}/`, true)
        .then(response => {
          if (response.data.status) {
            this.invoiceData = response.data.result
          }
        }).catch().finally(() => {
          this.isLoading = false
        })
    },
    async previewInvoice() {
      await this.$refs['invoice-pdf'].generatePdf()
    },
    async preparePdf({ html2pdf, options, pdfContent }) {
      await html2pdf().set(options).from(pdfContent).outputPdf()
        .get('pdf')
        .then(pdf => {
          window.open(pdf.output('bloburl'))
        })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-invoice";
</style>

<style lang="scss" scoped>
$base-color: #252323;
$table-border: #aaa;
.invoice-preview {
  color: $base-color;
  h1,h2.h3,h4,h5,h6 {
    color: $base-color;
  }
  .invoice-title {
    margin-bottom: 1.25rem;
  }
  .invoice-info {
    p {
      margin-bottom: 5px;
      span {
        font-weight: 500;
      }
    }
    h5 {
      margin-bottom: 15px;
    }
  }
  ::v-deep .border {
    border: none !important;
  }
  .card {
    ::v-deep .table {
      color: $base-color;
    }
  }
  .invoice-table {
    padding: 0 2.5rem;
    .table {
      color: $base-color;
    }
    ::v-deep .table-bordered {
      color: $base-color;
      tr {
        border-bottom: 1px solid $table-border;
      }
      thead {
        tr {
          border-top: 1px solid $table-border;
        }
      }
      th,td {
        border: 0;
        padding: 0.5rem;
        &:last-of-type {
          border-right: 1px solid $table-border;
          border-right: 1px solid $table-border;
        }
        &:first-of-type {
          border-left: 1px solid $table-border;
        }
      }
      td {
        font-size: 0.80rem;
        &.description {
          max-width: 280px;
        }
        &:not(:last-of-type) {
          border-right: none;
        }
      }
    }
    th {
      background-color: transparent !important;
    }
  }
}
</style>
