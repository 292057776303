<template>
  <b-sidebar
    id="payment-info-sidebar"
    ref="sidebar"
    sidebar-class="sidebar-lg"
    title="Payment Details"
    bg-variant="white"
    right
    backdrop
    no-header
    @hidden="clearFormData"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Payment Details
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <div>
        <validation-observer ref="transactionForm">
          <b-form
            class="p-1"
            @submit.prevent
          >
            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Payment Method"
                  label-for="payment-method"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="payment method"
                    rules="required"
                  >
                    <v-select
                      id="payment-method"
                      v-model="transactionForm.method"
                      placeholder="Select Payment Method"
                      class="status-filter-select"
                      :clearable="false"
                      :options="paymentMethods"
                      :reduce="option => option.value"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Amount"
                  label-for="amount"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="amount"
                    rules="required"
                  >
                    <b-form-input
                      id="amount"
                      v-model.number="transactionForm.amount"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <template v-if="transactionForm.method === 'MFS'">
                <b-col md="12">
                  <b-form-group
                    label="Payment"
                    label-for="payment"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="payment"
                      rules="required"
                    >
                      <v-select
                        id="payment"
                        v-model="transactionForm.gateway"
                        placeholder="Select Gateway"
                        class="status-filter-select"
                        :options="gatewayList"
                        :reduce="option => option.value"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Wallet Number"
                    label-for="wallet-number"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="wallet number"
                      rules="required"
                    >
                      <b-form-input
                        id="wallet-number"
                        v-model.number="transactionForm.wallet_number"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </template>
              <template
                v-if="transactionForm.method === 'CARD'
                  || transactionForm.method === 'EMI'"
              >
                <b-col md="12">
                  <b-form-group
                    label="Card Number"
                    label-for="card-number"
                  >
                    <div class="d-flex align-items-center card-input">
                      <validation-provider
                        #default="{ validate }"
                        vid="sixDigit"
                        name="First Block"
                        rules="required|length:6"
                      >
                        <b-form-input
                          id="six-digit"
                          v-model.number="sixDigit"
                          type="number"
                          placeholder="First Six Digit"
                          @input="updateCardNumber(validate)"
                        />
                      </validation-provider>
                      <span class="text-muted">******</span>
                      <validation-provider
                        #default="{ validate }"
                        vid="fourDigit"
                        name="Second Block"
                        rules="required|length:4"
                      >
                        <b-form-input
                          id="four-digit"
                          v-model.number="fourDigit"
                          type="number"
                          placeholder="Last Four Digit"
                          @input="updateCardNumber(validate)"
                        />
                      </validation-provider>
                    </div>
                    <small class="text-danger">{{ cardErrorMessage }}</small>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Cardholder's Name"
                    label-for="cardholder-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="cardholder's name"
                      rules="required"
                    >
                      <b-form-input
                        id="cardholder-name"
                        v-model="transactionForm.card_holder_name"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </template>
              <template v-if="transactionForm.method === 'BANK'">
                <b-col md="12">
                  <b-form-group
                    label="Beneficiary Bank"
                    label-for="beneficiary-bank"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="beneficiary bank"
                      rules="required"
                    >
                      <b-form-input
                        id="cardholder-name"
                        v-model="transactionForm.beneficiary_bank"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Customer Bank"
                    label-for="customer-bank"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="customer bank"
                      rules="required"
                    >
                      <b-form-input
                        id="customer-bank"
                        v-model="transactionForm.customer_bank"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </template>
              <b-col
                v-if="transactionForm.method !== 'CASH'"
                md="12"
              >
                <b-form-group
                  label="Transaction Id"
                  label-for="transaction-id"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="transaction id"
                    rules="required"
                  >
                    <b-form-input
                      id="amount"
                      v-model="transactionForm.trx_id"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="transactionForm.method === 'CARD' || transactionForm.method === 'EMI'"
                md="12"
              >
                <b-form-group
                  label="Approval Code"
                  label-for="approval-code"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="approval code"
                    rules="required"
                  >
                    <b-form-input
                      id="amount"
                      v-model="transactionForm.approval_code"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mt-1"
                  :disabled="isBtnLoading"
                  @click="validateForm()"
                >
                  <b-spinner
                    v-if="isBtnLoading"
                    small
                  />
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import api from '@/libs/api'
import helper from '@/mixins/helper'

export default {
  name: 'PaymentInfo',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [helper],
  props: {
    invoice: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      transactionForm: {
        amount: 0,
        method: 'CARD',
      },
      sixDigit: '',
      fourDigit: '',
      cardErrorMessage: '',
      isBtnLoading: false,
      paymentMethods: [
        { label: 'Card', value: 'CARD' },
        { label: 'MFS', value: 'MFS' },
        { label: 'EMI', value: 'EMI' },
        { label: 'Bank Transfer', value: 'BANK' },
        { label: 'Cash', value: 'CASH' },
      ],
      gatewayList: [
        { label: 'Bkash', value: 'BKASH' },
        { label: 'Nagad', value: 'NAGAD' },
        { label: 'upay', value: 'UPAY' },
      ],
    }
  },
  methods: {
    validateForm() {
      this.$refs.transactionForm.validate().then(success => {
        if (success) {
          this.submitTransactionForm()
        } else {
          const { errors } = this.$refs.transactionForm
          if (errors.fourDigit.length || errors.sixDigit.length) {
            this.cardErrorMessage = 'The card number field is required'
          }
          this.notificationMessage('warning', 'AlertCircleIcon', 'Warning',
            'Fill up all required fields')
        }
      })
    },
    updateCardNumber(validate) {
      validate().then(({ errors, valid }) => {
        if (valid) {
          this.transactionForm.card_number = `${this.sixDigit}******${this.fourDigit}`
          this.cardErrorMessage = ''
        } else {
          // eslint-disable-next-line prefer-destructuring
          this.cardErrorMessage = errors[0]
        }
      })
    },
    clearFormData() {
      this.transactionForm = {
        amount: 0,
        method: 'CARD',
      }
      this.sixDigit = ''
      this.fourDigit = ''
      this.cardErrorMessage = ''
      this.$refs.transactionForm.reset()
    },
    submitTransactionForm() {
      this.isBtnLoading = true
      this.transactionForm.sale_id = this.invoice.sale_id
      api.postData('/b2e_dashboard/stores/payments/', true, this.transactionForm).then(response => {
        if (response.data.status) {
          this.notificationMessage('success', 'CheckIcon', 'Success',
            'Your request has been successfully processed')
          this.$emit('getUpdatedInvoice')
          this.$root.$emit('bv::toggle::collapse', 'payment-info-sidebar')
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong')
      }).finally(() => {
        this.isBtnLoading = false
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.card-input {
  border: 1px solid #d8d6de;
  border-radius:0.357rem;
  gap: 10px;
  ::v-deep .form-control {
    border: none;
    padding-top: 0;
    padding-bottom: 0;
  }
  span {
    letter-spacing: 4px;
  }
  .input-label {
    padding: 5px;
  }
}
</style>
